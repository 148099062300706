<template>
  <div class="view-wrapper">
    <div class="view">
      <v-card>
        <v-card-title style="padding: 4px 16px">
          <h6>Фильтр</h6>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row dense>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="dateFrom"
                  label="С"
                  type="date"
                  required
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="dateTo"
                  label="По"
                  type="date"
                  required
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            small
            :loading="loading"
            @click="apply"
          >
            Применить
          </v-btn>
          <v-btn
            small
            :loading="loading"
            @click="resetFilter"
          >
            Сбросить
          </v-btn>
          <v-spacer/>

        </v-card-actions>
      </v-card>
      <v-container>
        <v-row>
          <v-col>
            <h1>Общее количество найденных номеров:</h1>
            <h2>{{ overallCount }}</h2>
          </v-col>
          <v-col>
            <h1>Количество номеров, найденных впервые:</h1>
            <h2>{{ uniqueCount }}</h2>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import fetch from "@/utils/fetch";
import {firstDayOfTheMonth, lastDayOfTheMonth} from '@/utils/date'
import dateIsoFilter from "@/filters/dateIso.filter";





export default {
  data: () => ({
   
    loading: false,
    overallCount: 0,
    uniqueCount: 0,
    dateFrom: new Date(),
    dateTo: new Date()
  
  }),
  methods: {

 
    apply() {
      this.fetchItems();
    },
    async fetchItems() {
      this.loading = true;
      let res = await fetch.get('/api/oktell/view/phone-statistic?dateFrom=' + this.dateFrom + '&dateTo=' + this.dateTo);
      this.overallCount = res.overallCount;
      this.uniqueCount = res.uniqueCount;
      this.loading = false;
    },
    init() {
      this.resetFilter();
      this.fetchItems();
    },
    resetFilter() {
      this.dateFrom = dateIsoFilter(firstDayOfTheMonth());
      this.dateTo = dateIsoFilter(lastDayOfTheMonth());
    }

   
  },

  mounted() {
   this.init();
  }
};
</script>
